<script>
import Layout from '../../layouts/main'
import PageHeader from '@/components/page-header'
import appConfig from "@/app.config";
import Loader from '@/components/widgets/loader';
import Swal from 'sweetalert2'

import getFindByCategoryIdList from '../../../state/api/getFindByCategoryIdList';
import statusCategorySaveOrUpdate from '../../../state/api/statusCategorySaveOrUpdate.js'
import CategoryDelete from '../../../state/api/CategoryDelete.js'

export default {
    page: {
        title: "Contact Users List",
        meta: [{ name: "description", content: appConfig.description }]
    },
    components: { Layout, PageHeader, Loader },
    data() {
        return {
            title: "Category List",
            items: [
                {
                text: "Categories",
                href: "/"
                },
                {
                text: "Category List",
                active: true
                }
            ],
            catagoryList : [],
            searchText:"",
            page: 1,
            perPage: 20,
            pages: [],
        };        
    },
    methods:{
        async getCatagoryList(){
            const {CategoryIdList} = getFindByCategoryIdList()
            const response = await CategoryIdList();
            this.catagoryList = response.data;
            this.setPages(this.catagoryList)
        },
        toggle(id,durum){
            let data = this.catagoryList;
            async function statusCat() {
                const {statusCategory} = statusCategorySaveOrUpdate()
                await statusCategory(id,durum).
                then(res=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        data.filter(x=>x.id == id)[0].isActive =!data.filter(x=>x.id == id)[0].isActive;
                    }
                })
            }
            statusCat();
        },
        categoryDelete(id){
            const data = this.catagoryList;
            async function categoryDeleteFunction() {
                const {postgetCategoryDelete} = CategoryDelete()
                await postgetCategoryDelete(id).
                then(res=>{
                    if(res.isSuccess){
                        Swal.fire({
                            position: "top-end",
                            icon: "success",
                            title: "Your work has been saved",
                            showConfirmButton: false,
                            timer: 1500
                        })
                        data.filter(x=>x.id == id)[0].isDelete =true                        
                    }
                })
            }
            categoryDeleteFunction();
        },
        setPages(data) {
          let numberOfPages = Math.ceil(data.length / this.perPage);
          for (let index = 1; index <= numberOfPages; index++) {
            this.pages.push(index);
          }
        },
        paginate(data) {
          let page = this.page;
          let perPage = this.perPage;
          let from = page * perPage - perPage;
          let to = page * perPage;
          return data.slice(from, to);
        },
    },
    computed: {
        filteredList() {            
            if(this.searchText !=""){
              
              return this.catagoryList.filter(item => {
                  return item.name.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
            else{
                
              return this.displayedPosts.filter(item => {
                  return item.name.toLowerCase().includes(this.searchText.toLowerCase())
              })
            }
            
        },
        displayedPosts() {
          return this.paginate(this.catagoryList);
        },

        // filteredList() {
        //     return this.catagoryList.filter(item => {
        //         return item.name.toLowerCase().includes(this.searchText.toLowerCase())
        //     })
        // }
    },
    mounted(){
        this.getCatagoryList();
    },
    
}
</script>
<template>
    <Layout>
      <PageHeader :title="title" :items="items" />
        <Loader :loading="filteredList">
            <div class="row">       
                <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-9">
                                <div class="btn-group mb-3">
                                    <router-link class="btn btn-light dropdown-toggle btn-block" :to="{name:'category-add'}">
                                        <i class="mdi mdi-plus me-1"></i> {{$t('user.CategoryNew')}}
                                    </router-link>
                                </div>  
                            </div>
                            <div class="col-md-3">
                                <div class="mb-3 float-end w-100">
                                    <input class="form-control" type="search" :placeholder="$t('navbar.search.text')" v-model="searchText">
                                </div>  
                            </div>
                        </div>                                     
                        <div class="table-responsive">
                            <table class="table align-middle table-nowrap table-hover">
                                <thead class="table-light">
                                    <tr>
                                    <th scope="col">{{$t('user.CategoryTitle')}}</th>
                                    <th scope="col">{{$t('user.Name')}}</th>
                                    <th scope="col">{{$t('user.Status')}}</th>
                                    <th scope="col">{{$t('user.Action')}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="cat in filteredList.filter(x=>x.isDelete == false)" :key="cat.id">
                                        <td>
                                            <h5 class="font-size-14 mb-1" v-if="cat.parentId != null">
                                                {{catagoryList.find(x=>x.id == cat.parentId).name}}
                                            </h5>
                                            <h5 class="font-size-14 mb-1" v-else>
                                                Main Category
                                            </h5>
                                        </td>
                                        <td>
                                            <h5 class="font-size-14 mb-1">
                                                <router-link class="text-dark" :to="{name:'category-detail',params:{id:cat.id}}">
                                                    {{cat.name}}
                                                </router-link>
                                            </h5>
                                        </td>
                                        <td v-if="cat.isActive">
                                            <a href="javascript:void(0);" @click="toggle(cat.id, cat.isActive)" class="badge bg-success 123">{{$t('user.Active')}}</a>
                                        </td>
                                        <td v-else>
                                            <a href="javascript:void(0);" @click="toggle(cat.id, cat.isActive)" class="badge bg-danger 12">{{$t('user.Passive')}}</a>
                                        </td>
                                        <td>
                                            <ul class="list-inline font-size-20 contact-links mb-0">
                                                <li class="list-inline-item px-2">
                                                    <router-link v-b-tooltip.hover :title="$t('menuitems.edit.text')" :to="{name:'category-detail',params:{id:cat.id}}">
                                                        <i class="bx bx-edit-alt"></i>
                                                    </router-link>
                                                </li>
                                                <li class="list-inline-item px-2">
                                                    <router-link :to="{name:'categoryAddGallery',params:{id:cat.id}}" v-b-tooltip.hover :title="$t('user.AddGallery')">
                                                        <i class="bx bx-images"></i>
                                                    </router-link>
                                                </li>
                                                <li class="list-inline-item px-2">
                                                    <button v-b-tooltip.hover :title="$t('user.Delete')" class="text-danger border-0 bg-transparent" @click="categoryDelete(cat.id)">
                                                        <i class="bx bx-trash-alt"></i>
                                                    </button>
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="d-flex justify-content-end mt-3" v-if="!searchText">
                            <div class="pagination-wrap hstack gap-2">
                            <b-link class="page-item pagination-prev" href="#" v-if="page != 1" @click="page--">
                                Previous
                            </b-link>
                            <ul class="pagination listjs-pagination mb-0">
                                <li :class="{
                                active: pageNumber == page,
                                disabled: pageNumber == '...',
                                }" v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)" :key="index"
                                @click="page = pageNumber">
                                <b-link class="page" href="#">{{ pageNumber }}</b-link>
                                </li>
                            </ul>
                            <b-link class="page-item pagination-next" href="#" @click="page++" v-if="page < pages.length">
                                Next
                            </b-link>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </Loader>      
    </Layout>
  </template>
